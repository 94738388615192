<template>
  <div>
    <v-app-bar app light clipped-left elevation="0" style="border-bottom: 2px solid #ff0000!important;" color="white">
      <transition name="slide-x-transition">
        <v-app-bar-nav-icon @click="onClickMenu" v-if="sidebar">
          <v-icon v-if="drawer">mdi-backburger</v-icon>
          <v-icon v-else>mdi-menu</v-icon>
        </v-app-bar-nav-icon>
      </transition>
      <v-toolbar-title class="mr-16" @click="navigate">
        <div class="logo">idenfit</div>
      </v-toolbar-title>
      <v-toolbar-items>
        <template v-for="(item, index) in menuItems">
          <v-btn
            class="toolbar-btn"
            active-class="active-menu"
            text
            small
            depressed
            :ripple="false"
            :key="item.index"
            :to="item.to"
          >
            {{ item.text }}
          </v-btn>
          <v-divider inset class="ml-2 mr-2" vertical v-if="index < menuItems.length - 1" />
        </template>
      </v-toolbar-items>
      <v-spacer />
      <v-btn text color="primary" v-if="!terminal_status" @click="onClickClockRecord">
        <v-icon left>mdi-alarm-check</v-icon>
        {{ $t("menu.clock_in_out") }}
      </v-btn>
      <v-badge
        v-if="pendingFeedbackCount"
        :content="pendingFeedbackCount"
        :value="pendingFeedbackCount"
        color="green"
        overlap
      >
        <v-btn small icon @click="$router.push({ name: 'pending_feedbacks' })">
          <v-icon>tw-hr-feed-back</v-icon>
        </v-btn>
      </v-badge>
      <v-img
        v-if="organizationLogo"
        :src="organizationLogo"
        :lazy-src="organizationLogo"
        width="60"
        :max-width="80"
        :max-height="40"
        class="mr-3 ml-3"
      >
        <template v-slot:placeholder>
          <v-layout fill-height align-center justify-center ma-0>
            <v-progress-circular indeterminate color="grey lighten-5" />
          </v-layout>
        </template>
      </v-img>
      <v-menu left bottom transition="slide-y-transition" :nudge-width="60" :nudge-bottom="51">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-avatar size="40" :color="imageUrl ? 'white' : 'primary'">
              <template v-if="imageUrl !== null">
                <v-img :src="imageUrl" :lazy-src="imageUrl">
                  <template v-slot:placeholder>
                    <v-layout fill-height align-center justify-center ma-0>
                      <v-progress-circular indeterminate color="grey lighten-5" />
                    </v-layout>
                  </template>
                </v-img>
              </template>
              <span v-else class="white--text headline"
                >{{ (firstName && firstName.substring(0, 1)) || ""
                }}{{ (lastName && lastName.substring(0, 1)) || "" }}</span
              >
            </v-avatar>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item>
            <v-list-item-avatar tile color="grey lighten-4">
              <v-img v-if="imageUrl" :src="imageUrl"></v-img>
              <v-icon v-else>mdi-account</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ fullName }}</v-list-item-title>
              <v-list-item-subtitle>{{ organization }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="onClickPassChange">
            <v-list-item-avatar>
              <v-progress-circular :size="32" v-if="loading" />
              <v-icon v-else>mdi-lock-reset</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ $t("auth.change_password") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="onClickLogout">
            <v-list-item-avatar>
              <v-progress-circular :size="32" v-if="loading" />
              <v-icon v-else>mdi-logout</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ $t("auth.logout") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <app-clock-record v-if="clockRecordDialog" @close="clockRecordDialog = false" />
    <validation-observer ref="changePass">
      <pass-change-dialog
        @close="passChangeDialogReset"
        @save="passChangePost"
        :show="passChangeDialogValue"
        :loading="passLoading"
        :title="$t('auth.change_password')"
      >
        <v-flex xs12>
          <validation-provider :name="$t('auth.current_password')" rules="required" v-slot="{ errors }">
            <v-text-field
              class="mx-2"
              :error-messages="errors"
              :label="$t('auth.current_password')"
              autocomplete
              v-model="currentPassword"
              :append-icon="showOldPassword ? 'mdi-eye-off' : 'mdi-eye'"
              @click:append="showOldPassword = !showOldPassword"
              :type="showOldPassword ? 'text' : 'password'"
            ></v-text-field>
          </validation-provider>
        </v-flex>
        <v-flex xs12>
          <validation-provider
            vid="confirmation"
            :name="$t('auth.new_password')"
            rules="required|min:6"
            v-slot="{ errors }"
          >
            <v-text-field
              class="mx-2"
              :error-messages="errors"
              autocomplete
              :label="$t('auth.new_password')"
              v-model="password1"
              :append-icon="showPassword1 ? 'mdi-eye-off' : 'mdi-eye'"
              @click:append="showPassword1 = !showPassword1"
              :type="showPassword1 ? 'text' : 'password'"
            ></v-text-field>
          </validation-provider>
        </v-flex>
        <v-flex xs12>
          <validation-provider
            :name="$t('auth.re_new_password')"
            rules="required|confirmed:confirmation"
            v-slot="{ errors }"
          >
            <v-text-field
              class="mx-2"
              :error-messages="errors"
              autocomplete
              :re_new_password="$t('auth.new_password')"
              :label="$t('auth.re_new_password')"
              v-model="password2"
              :append-icon="showPassword2 ? 'mdi-eye-off' : 'mdi-eye'"
              @click:append="showPassword2 = !showPassword2"
              :type="showPassword2 ? 'text' : 'password'"
            ></v-text-field>
          </validation-provider>
        </v-flex>
      </pass-change-dialog>
    </validation-observer>
  </div>
</template>

<script>
  /**
   * @project personal-idenfit
   * @developer Halil Kılıçarslan
   * @description app-header Component Logic
   * @date 28.07.2020
   */
  import { mapGetters } from "vuex";
  import { EMPLOYEE } from "./query";

  export default {
    name: "app-header",
    components: { PassChangeDialog: () => import("@/components/Dialog") },
    data: vm => ({
      showPassword1: false,
      showPassword2: false,
      showOldPassword: false,
      currentPassword: null,
      password1: null,
      password2: null,
      drawer: true,
      passChangeDialogValue: false,
      clockRecordDialog: false,
      terminal_status: false,
      passLoading: false,
      employeeEmail: vm.$token_manager.getWorkEmail(),
      items: [
        {
          text: vm.$t("menu.dashboard"),
          to: { name: "dashboard" }
        },
        {
          text: vm.$t("menu.hr"),
          to: { name: "human_resources" },
          mobileSetting: vm.$enums.MOBILE_SETTINGS.HR_PAGE_DISABLED
        },
        {
          text: vm.$t("menu.time"),
          to: { name: "time" },
          mobileSetting: vm.$enums.MOBILE_SETTINGS.TIME_PAGE_DISABLED
        },
        {
          text: vm.$t("menu.leave"),
          to: { name: "leave" },
          mobileSetting: vm.$enums.MOBILE_SETTINGS.LEAVE_DISABLED
        }
      ]
    }),
    watch: {},
    computed: {
      menuItems() {
        return this.items.filter(item => !this.routeDisabled(item.mobileSetting));
      },
      sidebar() {
        return this.$route.matched[1].meta.sidebar;
      },
      ...mapGetters({
        loading: "auth/loading",
        firstName: "auth/firstName",
        lastName: "auth/lastName",
        fullName: "auth/fullName",
        organization: "auth/organization",
        imageUrl: "auth/imageUrl",
        organizationLogo: "auth/organizationLogo",
        routeDisabled: "auth/routeDisabled"
      }),
      pendingFeedbackCount() {
        return this.$store.state.auth.notRequiredPendingFeedbackCount;
      }
    },
    methods: {
      async passChangePost() {
        this.passLoading = true;
        if (!this.employeeEmail) {
          await this.fetchEmployeeEmail();
          await this.mobileLogin();
        } else {
          await this.mobileLogin();
        }
        this.passLoading = false;
      },
      async fetchEmployeeEmail() {
        return this.$apollo
          .query({
            query: EMPLOYEE,
            variables: {
              id: this.$store.state.auth.user.id
            }
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              this.employeeEmail = data.employee.workEmail;
            }
          })
          .catch(error => this.$helpers.showNotification(error.message))
          .finally(() => {});
      },
      changePassPost() {
        const payload = this.password1;
        this.$api.authenticationService.passChange(payload).then(response => {
          if (response && response.token && response.user) {
            this.passChangeDialogReset();
          } else {
            this.$helpers.showNotification("network_error", "error");
          }
        });
      },
      passChangeDialogReset() {
        this.currentPassword = null;
        this.password1 = null;
        this.password2 = null;
        this.passChangeDialogValue = false;
        this.$refs.changePass.reset();
      },
      mobileLogin() {
        return this.$refs.changePass.validate().then(valid => {
          if (valid) {
            const payload = {
              username: this.employeeEmail,
              password: this.currentPassword
            };
            return this.$store
              .dispatch("auth/MOBILELOGIN", payload)
              .then(isLoggedIn => {
                if (isLoggedIn) {
                  this.changePassPost();
                } else {
                  this.$helpers.showNotification(this.$t("auth.invalid_password"), "error", true);
                }
              })
              .catch(error => {
                if (!error.response) {
                  this.$helpers.showNotification("network_error", "error");
                } else {
                  this.$helpers.showNotification(this.$t("auth.invalid_password"), "error", true);
                }
              });
          }
        });
      },
      onClickPassChange() {
        this.passChangeDialogValue = true;
      },
      navigate() {
        this.$router.push({ name: "dashboard" });
      },
      onClickLogout() {
        this.$store
          .dispatch("auth/LOGOUT")
          .then(() => {
            this.$router.replace({ name: "login" });
          })
          .catch(e => {
            this.$helpers.showNotification(e.toString());
          });
      },
      onClickMenu() {
        this.drawer = !this.drawer;
        this.$eventBus.$emit("open:sidebar", this.drawer);
      },
      onClickClockRecord() {
        this.clockRecordDialog = true;
      },
      fetchItems() {
        this.$store
          .dispatch("auth/TERMINAL_STATUS")
          .then(status => {
            this.terminal_status = status;
          })
          .catch(e => this.$helpers.showNotification(e.message));
      },
      fetchPendingFeedbacks() {
        this.$store
          .dispatch("auth/FETCH_PENDING_FEEDBACKS", false)
          .then()
          .catch(e => this.$helpers.showNotification(e.message));
      },
      fetchMobileSettings() {
        this.$store
          .dispatch("auth/FETCH_MOBILE_SETTINGS")
          .then()
          .catch(e => this.$helpers.showNotification(e.message));
      }
    },
    created() {
      this.fetchItems();
      this.fetchPendingFeedbacks();
      this.fetchMobileSettings();
      this.$eventBus.$on("close:sidebar", status => (this.drawer = status));
    }
  };
</script>

<style scoped>
  .logo {
    font-family: "Paytone One", sans-serif;
    font-size: 32px;
    font-weight: 400;
    color: var(--v-primary-base);
    letter-spacing: 0;
    cursor: pointer;
    margin-left: 0;
  }

  .toolbar-btn {
    text-transform: capitalize;
    color: #333;
  }

  .toolbar-btn:before {
    transition: none !important;
  }

  .toolbar-btn:hover:before {
    background-color: transparent;
  }

  .toolbar-btn:active:before,
  .toolbar-btn:focus:before {
    background-color: transparent;
  }

  .active-menu {
    color: var(--v-primary-base);
  }

  .active-menu:before {
    background-color: transparent;
  }
</style>
